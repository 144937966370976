import {Modal, Collapse} from 'bootstrap';
import * as Sentry from "@sentry/browser";

window.Modal = Modal;
window.Collapse = Collapse;

Sentry.init({
    dsn: "https://77036421035f4a0993904ac979c436a6@o112438.ingest.sentry.io/1220746",
    maxBreadcrumbs: 50,
});
